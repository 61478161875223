<template>
  <v-data-table
    v-model="memberSelected"
    :items="items"
    item-key="id"
    show-select
    :page.sync="paginate.page"
    :items-per-page.sync="paginate.limit"
    :server-items-length="paginate.total"
    :headers="headers"
    :loading="loading"
    :footer-props="dataTableFooter"
    loading-text="กรุณารอสักครู่...">
    <template v-slot:[`item.tel`]="{ item }">
      <router-link
        :to="`/members/${item.id}`"
        link>
        {{ item.tel }}
      </router-link>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ fullNameCompute(item) }}
    </template>
    <template v-slot:[`item.birthDate`]="{ item }">
      {{ birthDateCompute(item) }}
    </template>
    <template v-slot:[`item.spending.current`]="{ item }">
      {{ item.spending.current | showFullPriceFormat() }}
    </template>
    <template v-slot:[`item.edit`]="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="item.type === 'normal'"
            link
            @click="changeTypeMember(item, 'vip')">
            <span>
              เพิ่มเป็น VIP
            </span>
          </v-list-item>
          <v-list-item
            v-if="item.type !== 'vvip'"
            link
            @click="changeTypeMember(item, 'vvip')">
            <span>
              เพิ่มเป็น VVIP
            </span>
          </v-list-item>
          <v-list-item
            v-if="item.type !== 'normal'"
            link
            @click="sendPromotion(item)">
            <span>
              ส่งโค้ดโปรโมชั่น
            </span>
          </v-list-item>
          <v-list-item
            link
            @click="editClicked(item)">
            <span>
              แก้ไข
            </span>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      memberSelected: [],
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      },
      headers: [
        {
          text: 'ID',
          align: 'center',
          value: 'id'
        },
        {
          text: 'Type',
          align: 'center',
          value: 'type'
        },
        {
          text: 'เบอร์โทรศัพท์',
          align: 'center',
          value: 'tel'
        },
        {
          text: 'ชื่อ-นามสกุล',
          align: 'center',
          value: 'name'
        },
        {
          text: 'วันเกิด',
          align: 'center',
          value: 'birthDate'
        },
        {
          text: 'Email',
          align: 'center',
          value: 'email'
        },
        {
          text: 'ยอดซื้อ',
          align: 'center',
          value: 'spending.current'
        }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    memberSelected: {
      handler (values) {
        this.returnMembers(values)
      },
      deep: true
    }
  },
  mounted () {
    this.initHeaders()
  },
  methods: {
    initHeaders () {
      if (this.editMode) {
        this.headers.push(
          {
            text: 'ตัวเลือก',
            align: 'center',
            sortable: false,
            value: 'edit'
          }
        )
      }
    },
    fullNameCompute (member) {
      return `${member.firstName} ${member.lastName}`
    },
    birthDateCompute (member) {
      return member.birthDate ? this.$dayjs(member.birthDate).format('DD MMM YYYY') : '-'
    },
    editClicked (member) {
      this.$emit('edit', member)
    },
    changeTypeMember (member, type) {
      this.$emit('change-type', member, type)
    },
    returnMembers (values) {
      this.$emit('return-members', values)
    },
    sendPromotion (member) {
      this.$emit('send-promo', member)
    }
  }
}
</script>
