<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col
      cols="12"
      md="2">
      <v-select
        v-model="innerFilter.type"
        :items="types"
        label="Type"
        hide-details
        dense
        outlined />
    </v-col>
    <v-col
      cols="12"
      md="2">
      <v-select
        v-model="innerFilter.birthMonth"
        :items="months"
        item-text="th"
        item-value="index"
        label="เดือนเกิด"
        hide-details
        dense
        outlined />
    </v-col>
    <v-col
      cols="12"
      md="2" />
    <v-col
      cols="12"
      md="6">
      <v-row justify="end">
        <v-col
          cols="8"
          md="6"
          class="d-flex align-center search-section">
          <v-switch
            v-model="innerFilter.fullSearch"
            true-value="1"
            false-value="0"
            color="secondary" />
          <v-text-field
            v-if="innerFilter.fullSearch === '1'"
            v-model="innerFilter.search"
            dense
            label="ค้นหาทั้งหมด"
            hide-details
            outlined
            @keydown.native.enter="bindValue()" />
          <vue-tel-input-vuetify
            v-else
            v-model="innerFilter.search"
            label="ค้นหาด้วยเบอร์"
            default-country="th"
            dense
            outlined
            hide-details
            style="width: 100%;"
            @country-changed="countryChange($event)"
            @keydown.native.enter="bindValue()" />
        </v-col>
        <v-col
          cols="auto"
          class="d-flex align-center">
          <v-btn
            color="primary"
            @click="bindValue()">
            ค้นหา
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="2">
      <v-select
        v-model="innerFilter.createdIn"
        :items="createdIns"
        label="ช่องทาง"
        dense
        hide-details
        outlined />
    </v-col>
    <v-col
      cols="12"
      md="4"
      class="d-flex">
      <v-text-field
        v-model="innerFilter.spendingMinPrice"
        label="ยอดซื้อ(ขั้นต่ำ)"
        dense
        type="number"
        placeholder="0"
        hide-details
        outlined />
      <p class="mx-2 mt-2">
        -
      </p>
      <v-text-field
        v-model="innerFilter.spendingMaxPrice"
        label="ยอดซื้อ(สูงสุด)"
        dense
        type="number"
        placeholder="100000"
        hide-details
        outlined />
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="2">
      <v-btn
        v-if="editMode"
        dense
        block
        color="primary"
        :to="{ name: 'CreateMember'}">
        สร้างสมาชิก
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import months from '@/assets/js/Months'

export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searching: null,
      telInfo: null,
      types: [
        { text: 'All', value: '' },
        { divider: true },
        { text: 'Member', value: 'normal' },
        { text: 'VIP', value: 'vip' },
        { text: 'VVIP', value: 'vvip' }
      ],
      createdIns: [
        { text: 'All', value: '' },
        { divider: true },
        { text: 'Manual', value: 'manual' },
        { text: 'Manual Storefront', value: 'manual_storefront' },
        { text: 'Auto Storefront', value: 'auto_storefront' },
        { text: 'Manual Web', value: 'manual_web' },
        { text: 'Auto Web', value: 'auto_web' },
        { text: 'Old System', value: 'old_system' },
        { text: 'Line OA', value: 'line_oa' },
        { text: 'Line Web', value: 'line_web' },
        { text: 'Gift Campaign', value: 'gift_campaign' }
      ]
    }
  },
  computed: {
    innerFilter () {
      return this.filter
    },
    months () {
      return [{ th: 'ทั้งหมด', index: '' }, { divider: true }, ...months]
    }
  },
  watch: {
    'innerFilter.search': {
      handler (value) {
        if (
          this.innerFilter.fullSearch === '0'
          && value.length === 12
          && this.telInfo.dialCode === '66'
        ) {
          this.innerFilter.search = `+66${value.slice(0)}`
        }
      },
      deep: true
    }
  },
  methods: {
    bindValue () {
      this.$emit('filter-clicked', this.innerFilter)
    },
    countryChange (data) {
      this.telInfo = data
    }
  }
}
</script>

<style scoped>
.filter-btn {
  margin-top: 2px;
}
</style>

<style>
.search-section .country-code .v-select .v-input__slot {
  margin-bottom: 0 !important;
}
.search-section .country-code .v-select .v-text-field__details {
  min-height: 0 !important;
  margin-bottom: 0 !important;
  height: 0 !important;
}
</style>
