var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"item-key":"id","show-select":"","page":_vm.paginate.page,"items-per-page":_vm.paginate.limit,"server-items-length":_vm.paginate.total,"headers":_vm.headers,"loading":_vm.loading,"footer-props":_vm.dataTableFooter,"loading-text":"กรุณารอสักครู่..."},on:{"update:page":function($event){return _vm.$set(_vm.paginate, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.paginate, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.paginate, "limit", $event)}},scopedSlots:_vm._u([{key:"item.tel",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/members/" + (item.id)),"link":""}},[_vm._v(" "+_vm._s(item.tel)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullNameCompute(item))+" ")]}},{key:"item.birthDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.birthDateCompute(item))+" ")]}},{key:"item.spending.current",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("showFullPriceFormat")(item.spending.current))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(item.type === 'normal')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeTypeMember(item, 'vip')}}},[_c('span',[_vm._v(" เพิ่มเป็น VIP ")])]):_vm._e(),(item.type !== 'vvip')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.changeTypeMember(item, 'vvip')}}},[_c('span',[_vm._v(" เพิ่มเป็น VVIP ")])]):_vm._e(),(item.type !== 'normal')?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.sendPromotion(item)}}},[_c('span',[_vm._v(" ส่งโค้ดโปรโมชั่น ")])]):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.editClicked(item)}}},[_c('span',[_vm._v(" แก้ไข ")])])],1)],1)]}}],null,true),model:{value:(_vm.memberSelected),callback:function ($$v) {_vm.memberSelected=$$v},expression:"memberSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }