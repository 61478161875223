export default [
  {
    abbreviation: 'Jan',
    eng: 'January',
    th: 'มกราคม',
    index: '01'
  },
  {
    abbreviation: 'Feb',
    eng: 'February',
    th: 'กุมภาพันธ์',
    index: '02'
  },
  {
    abbreviation: 'Mar',
    eng: 'March',
    th: 'มีนาคม',
    index: '03'
  },
  {
    abbreviation: 'Apr',
    eng: 'April',
    th: 'เมษายน',
    index: '04'
  },
  {
    abbreviation: 'May',
    eng: 'May',
    th: 'พฤษภาคม',
    index: '05'
  },
  {
    abbreviation: 'Jun',
    eng: 'June',
    th: 'มิถุนายน',
    index: '06'
  },
  {
    abbreviation: 'Jul',
    eng: 'July',
    th: 'กรกฏาคม',
    index: '07'
  },
  {
    abbreviation: 'Aug',
    eng: 'August',
    th: 'สิงหาคม',
    index: '08'
  },
  {
    abbreviation: 'Sep',
    eng: 'September',
    th: 'กันยายน',
    index: '09'
  },
  {
    abbreviation: 'Oct',
    eng: 'October',
    th: 'ตุลาคม',
    index: '10'
  },
  {
    abbreviation: 'Nov',
    eng: 'November',
    th: 'พฤศจิกายน',
    index: '11'
  },
  {
    abbreviation: 'Dec',
    eng: 'December',
    th: 'ธันวาคม',
    index: '12'
  }
]
